import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from "react-anchor-link-smooth-scroll";

import styled from "../../../components/images/styledc.png";

export const BlocksHero = () => {

  return (
    <div className="py-5" >
      <div  className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header">
              <h2 className="hero__lead " style={{maxWidth: "600px", margin: "auto"}}>
               Template blocks to rapidly prototype and build any web page
              </h2>
              <p className="hero__text" style={{ maxWidth: "700px" }}>
                Windframe Blocks is a collection of template blocks built with tailwind css, to rapidly build a webpage or prototype any idea. 
                These tailwind css blocks plugs in directly to <a href="https://windframe.dev/"> Windframe </a>(a design, prototyping and code tool) to enable you easily edit, preview, save or export your designs
              </p>

              <div className="hero__buttons">
                <Link href="https://windframe.dev" className="">
                  <span className="btn__text">Learn more about windframe</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
