import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from '../../../components/AppContext';
import NavBar from '../../../components/NavBar/WindframeNav';
import { Footer } from '../../../components/Footer/Footer';
import { BlocksHero } from '../../../components/Windframe/Windframe/BlocksHero';
import { CtaBlocks } from '../../../components/Windframe/Windframe/CtaBlocks';
import { Block } from '../../../components/Windframe/Windframe/Block/Block';
import loading from '../../../components/images/loading.gif';
// import blockList from '../../../utils/blockslist';
import '../../../styles/scss/style.scss'
import './blocks.scss';

const Blocks = () => {
  // const [blocks] = useState(JSON.parse(window.localStorage.getItem('blocks')));

  return (
    <AppContext>
      <Helmet>
        <title>
          Windframe Blocks - Tailwind CSS Template blocks to rapidly prototype and build any web
          page
        </title>
        <meta
          name="title"
          content="Windframe Blocks - Tailwind CSS Template blocks to rapidly prototype and build any web page"
        />
        <meta
          name="description"
          content="Windframe Blocks is a collection of template blocks built with tailwind css, to rapidly build a webpage or prototype any idea"
        />
        <meta
          property="og:title"
          content="Windframe Blocks - Tailwind CSS Template blocks to rapidly prototype and build any web page"
        />
        <meta
          property="og:description"
          content="Windframe Blocks is a collection of template blocks built with tailwind css, to rapidly build a webpage or prototype any idea"
        />
        <meta
          property="twitter:title"
          content="Windframe Blocks - Tailwind CSS Template blocks to rapidly prototype and build any web page"
        />
        <meta
          property="twitter:description"
          content="Windframe Blocks is a collection of template blocks built with tailwind css, to rapidly build a webpage or prototype any idea"
        />
        <link rel="canonical" href="https://windframe.dev/blocks/" />
        <meta property="og:url" content="https://windframe.dev/blocks/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <UserContext.Consumer>
        {({ user, blocks }) => (
          <div>
            {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
              <p className="text-xl">
                We are live on product hunt today 🚀 and would appreciate your support!
              </p> */}
              {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
              {/* <a
                href="https://www.producthunt.com/posts/windframe-2-0-2"
                className="px-3 text-white underline text-xl font-bold !underline"
                style={{ textDecoration: 'underline' }}
              >
                Support on Product Hunt
              </a>
              </div> */}
            <div className="container">
              <NavBar user={user} />
            </div>
            <BlocksHero />
            <div className="blocks-container container">
              {Object.keys(blocks).length > 0 ? (
                <>
                  <div className="block-section">
                    <h3>Navigation</h3>
                    <div className="blocklist">
                      {blocks?.navigation
                        ?.filter(block => block.free)
                        .map(block => (
                          <Block key={block.id} id={block.id} block={block} />
                        ))}
                      {blocks?.navigation
                        ?.filter(block => !block.free)
                        .map(block => (
                          <Block key={block.id} id={block.id} block={block} />
                        ))}
                    </div>
                  </div>
                  <div className="block-section">
                    <h3>Headers</h3>
                    <div className="blocklist">
                      {blocks?.header
                        ?.filter(block => block.free)
                        .map(block => (
                          <Block key={block.id} id={block.id} block={block} />
                        ))}
                      {blocks?.header
                        ?.filter(block => !block.free)
                        .map(block => (
                          <Block key={block.id} id={block.id} block={block} />
                        ))}
                      {/* {blocks?.header?.map((block)=><Block id={block.id} block={block} />)} */}
                    </div>
                  </div>
                  {Object.keys(blocks).map(section => {
                    return (
                      section !== 'navigation' &&
                      section !== 'footers' &&
                      section !== 'header' &&
                      section !== 'fullPages' && (
                        <div className="block-section">
                          <h3>{section}</h3>
                          <div className="blocklist">
                            {blocks[section]
                              .filter(block => block.free)
                              .map(block => (
                                <Block key={block.id} id={block.id} block={block} />
                              ))}
                            {blocks[section]
                              .filter(block => !block.free)
                              .map(block => (
                                <Block key={block.id} id={block.id} block={block} />
                              ))}
                          </div>
                        </div>
                      )
                    );
                  })}
                  <div className="block-section">
                    <h3>Footer</h3>
                    <div className="blocklist">
                      {blocks?.footers
                        ?.filter(block => block.free)
                        .map(block => (
                          <Block key={block.id} id={block.id} block={block} />
                        ))}
                      {blocks?.footers
                        ?.filter(block => !block.free)
                        .map(block => (
                          <Block key={block.id} id={block.id} block={block} />
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className="loading">
                  <img src={loading} alt="loading gif" style={{ margin: 'auto' }} />
                </div>
              )}
            </div>
            <CtaBlocks />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default Blocks;
